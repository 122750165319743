/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import { Homepage } from "../../../components/homepage"

export const pageQuery = graphql`
  query brandenburg {
    file(relativePath: { eq: "parking-alicante-elche-front.jpeg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 585, height: 439)
      }
    }
    posts: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___order_parking] }
      filter: { frontmatter: { template: { eq: "parking-post" } } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            slug
            title
            isParkVia
            doNotShowOnMainPage
            featuredImage {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 345, height: 260)
              }
            }
          }
        }
      }
    }
  }
`

const frontmatter = {
  template: "index-page",
  slug: "/",
  title: "Parkingi Lotnisko Alicante Hiszpania",
  tagline: "Alicante, Hiszpania",
  featuredImage: "/assets/parking-alicante-elche-front.jpeg",
  cta: {
    ctaText: "Wyszukaj parking",
    ctaLink: "#search",
  },
}
const HomePage = ({ data }) => (
  <Homepage
    frontmatter={frontmatter}
    data={data}
    descriptionComponent={
      <div>
        <b>Adres:</b> 03195 L’Altet, Alicante, Comunitat Valenciana, Hiszpania
        <p>
          Lotnisko Alicante-Elche jest położone zaledwie 9 kilometrów na
          południowy zachód od miasta Alicante. Jak każde duże lotnisko, oferuje
          szeroki wybór możliwości parkowania, dostosowany do różnych potrzeb i
          budżetów swoich pasażerów.
        </p>
      </div>
    }
  >
    <h2 id="parking-przy-lotnisku-alicante---twoje-najlepsze-opcje">
      Parking Przy Lotnisku Alicante Elche - Twoje Najlepsze Opcje
    </h2>
    <p>
      Nasza strona &quot;Parking przy Lotnisku Alicante&quot; dostarcza
      najważniejszych informacji dotyczących możliwości parkowania na terenie
      lotniska Alicante-Elche (ALC), piątego co do wielkości lotniska w
      Hiszpanii i bramy do regionu Costa Blanca.
    </p>
    <h2 id="dlaczego-wybrać-parking-na-lotnisku-alicante">
      Dlaczego Wybrać Parking na Lotnisku Alicante?
    </h2>

    <h2 id="dogodna-lokalizacja">Dogodna Lokalizacja</h2>
    <p>
      Kiedy planujesz podróż, parkowanie na lotnisku Alicante to najwygodniejsza
      opcja. To idealne rozwiązanie, jeśli chcesz zostawić samochód bezpiecznie
      zaparkowany na czas podróży.{" "}
    </p>
    <h2 id="różne-opcje-parkingu">Różne Opcje Parkingu</h2>
    <p>
      Na naszej stronie zaprezentujemy Ci różne opcje parkingu dostępne na
      lotnisku Alicante. Szczegółowo omówimy gdzie znajdują się strefy
      parkowania, jakie są ceny oraz jakie dodatkowe usługi są dostępne, takie
      jak mycie samochodu.
    </p>
    <h2 id="możliwość-rezerwacji-miejsca">Możliwość Rezerwacji Miejsca</h2>
    <p>
      Aby zapewnić Ci pełen komfort i spokój, na naszej stronie umożliwiamy
      rezerwację miejsca parkingowego na lotnisku Alicante. Dzięki temu
      zyskujesz pewność, że miejsce będzie na Ciebie czekać, niezależnie od
      tego, o której godzinie przylecisz czy wylecisz.
    </p>
    <h2 id="bezpieczeństwo-twego-samochodu">Bezpieczeństwo Twego Samochodu</h2>
    <p>
      Bezpieczeństwo Twojego samochodu jest dla nas priorytetem. Dlatego
      dostarczamy Ci dokładnych informacji o środkach bezpieczeństwa
      obowiązujących na parkingach lotniska Alicante, takich jak monitoring czy
      ochrona.
    </p>
    <p>
      Zaplanuj swą podróż z naszą stroną &quot;Parking przy Lotnisku
      Alicante&quot; i ciesz się spokojem, wiedząc, że Twój samochód jest
      bezpieczny na czas Twojej nieobecności.
    </p>
    <p>
      <a href="/#search">
        Zarezerwuj miejsce parkingowe na lotnisku Alicante już dzisiaj!
      </a>
    </p>

    <h3 id="dojazd-autobusem">
      <strong>Dojazd autobusem</strong>
    </h3>
    <p>
      Lotnisko Alicante-Elche obsługuje wiele linii autobusowych, które oferują
      bezpośrednie połączenia do Alicante, Torrellano, Elche, Benidorm, Calpe,
      Santa Pola, Guardamar, Torrevieja i Murcji.
    </p>
    <p>
      <strong>Autobusy miejskie:</strong>
      <em>Linia C-6: Lotnisko Alicante – Alicante</em>
      Komunikacja miejska na tej trasie działa od 6:40 do 23:00 co 20 minut.
      Autobusy zatrzymują się na stacji kolejowej i w innych gruntownie
      wybranych miejscach. Bilety są dostępne do zakupu w autobusie. Ceny
      biletów wynoszą:{" "}
    </p>
    <ul>
      <li>Zwykły bilet - 3,85 EUR</li>
      <li>Bilet 10-przejazdów - 12 EUR</li>
      <li>Bilet dla emerytów - 0,95 EUR</li>
    </ul>
    <p>
      <em>Linia 1A: Elche - Lotnisko Alicante</em>
      Ta linia oferuje połączenie lotniska z centrum Elche. Bilety są dostępne
      do zakupu w autobusie. Ceny biletów wynoszą:
    </p>
    <ul>
      <li>Zwykły bilet - 1,50 EUR</li>
      <li>Bilet 20-przejazdów - 24 EUR</li>
      <li>Bilet dla emerytów - 0,95 EUR</li>
    </ul>
    <p>
      <em>Linia 1B: Elche - Lotnisko Alicante - Arenales del Sol</em>
      Ta linia oferuje połączenie lotniska z centrum Elche. Bilety są dostępne
      do zakupu w autobusie. Ceny biletów wynoszą:
    </p>
    <ul>
      <li>Zwykły bilet - 1,50 EUR</li>
      <li>Bilet 20-przejazdów - 26 EUR</li>
      <li>Bilet dla emerytów - 0,95 EUR</li>
    </ul>
    <p>
      <em>Linia Alicante – Murcia</em>
      Ta linia oferuje połączenie lotniska z Murcią. Bilety są dostępne do
      zakupu online lub w autobusie. Ceny biletów wynoszą:
    </p>
    <ul>
      <li>Zwykły bilet - 5,22 EUR</li>
      <li>Bilet w dwie strony - 9,40 EUR</li>
    </ul>
    <p>
      <em>Linia Alicante - Santa Pola</em>
      Ta linia oferuje połączenie lotniska z Santa Pola. Bilety są dostępne do
      zakupu w autobusie. Ceny biletów wynoszą:
    </p>
    <ul>
      <li>Zwykły bilet - 2,45 EUR</li>
      <li>Bilet 10-przejazdów - 21 EUR</li>
    </ul>
    <h3 id="dojazd-pociągiem">
      <strong>Dojazd pociągiem</strong>
    </h3>
    <p>
      Komunikacja kolejowa łączy miasta Benidorm, Alicante i Denia wzdłuż
      wybrzeża Costa Blanca. Lotnisko jest także połączone z centrum miasta
      Alicante dzięki linii autobusowej C6, która łączy je ze stacją Luceros.
    </p>
    <h3 id="dojazd-taksówką">
      <strong>Dojazd taksówką</strong>
    </h3>
    <p>
      Lotnisko obsługuje korporację Radio Taxi Elche. Stanowiska tego
      przewoźnika znajdują się przed halą przylotów. Opłata minimalna wynosi
      3,80 EUR w ramach standardowej taryfy i 4,30 EUR w ramach specjalnej
      taryfy. Dodatkowo obowiązuje opłata lotniskowa w wysokości 4,25 EUR.
    </p>
  </Homepage>
)

export default HomePage
